<template>
  <div class="tc-userLogin">
    <div class="userLogin-layout">
      <div class="userLogin_header">
        <p>谦&nbsp;&nbsp;雅</p>
      </div>
      <div class="mian">
        <div class="title">
          <p>欢迎来到谦雅心理CT</p>
        </div>
        <div class="login-tab">
          <div class="tab">
            <span class="isClick">账户登陆</span>
          </div>
          <div class="content">
            <el-form :model="ruleForm" class="demo-ruleForm" :rules="rules">
              <el-form-item prop="username">
                <el-input prefix-icon="el-icon-user" v-model="ruleForm.username" placeholder="用户名"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  prefix-icon="el-icon-lock"
                  type="password"
                  v-model="ruleForm.password"
                  placeholder="密码"
                  @keyup.enter.native="userLogin"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="loginBtn">
          <div class="logBtn">
            <el-button type="primary" round @click="userLogin"><span v-if="!isLogin">登 录</span> <span v-if="isLogin"><i class="el-icon-loading"></i>正在登陆</span></el-button>
          </div>
          <div class="loginRes" @click="goRegistration()">
            <!-- <router-link to="/userRegistration">
              <i class="el-icon-plus"></i> 创建用户
            </router-link>-->
            <a href="javascript:;">
              <i class="el-icon-plus"></i>创建用户
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login, getadmininfo } from "@/api/login.js";
import { getListConfig } from "@/api/comm.js";
export default {
  data() {
    return {
      // 校验规则
      rules: {
        // 用户名
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        // 密码
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      ruleForm: {
        username: "",
        password: "",
        type: 1,
      },
      isLogin:false
    };
  },
  created() {
    // if (screen.width > 1000) {
    //   //console.log("web", screen.width);
    //   this.$router.push("/login");
    // }
    // 判断全局配置是否存在
    if (!localStorage.getItem("config")) {
      getListConfig().then((res) => {
        if (res.code == 400200) {
          document.title = res.data.systematic_name;
          localStorage.setItem("config", JSON.stringify(res.data));
        }
      });
    }
    if (localStorage.getItem("isReg") == 1) {
      this.$router.push(`/userRegistration`);
    }
  },
  methods: {
    // 登陆
    userLogin() {
      let { username, password, type } = this.ruleForm;
      let param = {
        userName: username,
        password,
        type,
      };
      if (username === "" && password === "") {
        this.$alert("请正确输入用户名和密码", "通知", {
          confirmButtonText: "确定",
        });
        return;
      } else {
        this.isLogin = true
        login(param).then((res) => {
          if (res.code === 400200) {
        
            // 1. 成功提示
            this.$message({
              message: "恭喜你，登录成功",
              type: "success",
              duration: 1000,
              showClose: true,
            });
            // 2. 跳转到答题页
            localStorage.setItem("usertoken", "usertoken");
                this.isLogin = false
            this.$router.push("/answer_content/answer_list");
          }else{
             this.isLogin = false
          }
        }).catch(err=>{
           this.isLogin = false
        });
      }
    },
    // 判断手机
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 前往注册页面
    goRegistration() {
      this.$router.push(`/userRegistration`);
      // if (localStorage.getItem("config")) {
      //   let config = JSON.parse(localStorage.getItem("config"));
      //   console.log(config.user_register);
      //   if (config.user_register == 1) {
      //   } else {
      //     this.$alert("注册已关闭，请联系管理员", "通知", {
      //       confirmButtonText: "确定",
      //     });
      //   }
      // }
    },
  },
  mounted() {
    if (this._isMobile()) {
      // alert("手机端");
    } else {
      this.$confirm("是否跳转电脑版界面", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.replace("/login");
        })
        .catch(() => {});
      // alert("pc端");
      // this.$router.replace("/pc_index");
    }
  },
};
</script>

<style lang="less">
.tc-userLogin {
  width: 100%;
  zoom: 1;
  box-sizing: border-box;
  height: 100%;
  min-height: 700px;
  a {
    color: black;
  }
  .userLogin-layout {
    max-width: 768px;
    min-width: 325px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    .userLogin_header {
      flex: 1.5;
      background: url("../../assets/images/userLoginBack.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 40px;
        font-weight: 900;
        color: white;
        text-align: center;
        text-shadow: black 2px 2px;
      }
    }
    .mian {
      flex: 5;
      display: flex;
      flex-direction: column;
      .title {
        flex: 1.5;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 18px;
          color: #62decb;
          letter-spacing: 2px;
        }
      }
      .login-tab {
        flex: 3;
        display: flex;
        flex-direction: column;

        .tab {
          width: 70%;
          margin: 0 auto;
          flex: 1;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          border-bottom: 2px solid #62decb;
          span {
            color: #62decb;
            line-height: 30px;
            padding: 0 24.2px;
          }
          .isClick {
            font-weight: 700;
          }
        }
        .content {
          flex: 6;
          padding: 0 20px;
          //   background: rgb(189, 228, 148);
          display: flex;
          justify-content: center;
          align-items: center;
          .el-form-item {
            .el-form-item__content {
              .el-input__inner {
                border: 1px solid transparent;
                border-bottom: 1px #cccccc solid;
              }
            }
            .el-input__icon {
              color: #62decb;
              font-size: 20px;
            }
          }
        }
      }
      .loginBtn {
        flex: 3;
        width: 70%;
        margin: 0 auto;
        .el-button.is-round {
          width: 100%;
        }
        .el-button--primary {
          background: #62decb;
          border: 0;
          span {
            font-size: 20px;
          }
        }
        .loginRes {
          margin-top: 20px;
          text-align: center;
          color: #aaaaaa;
          a {
            color: #aaaaaa;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .tc-userLogin {
    min-height: 550px;
  }
}
</style>